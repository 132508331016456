<template>
  <div class="c-row">
    <div class="ui-m-4 ui-my-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Grupo {{ selectedGroup.name }}
          </span>
        </div>
      </div>

      <!-- Subtítulo -->
      <div class="c-row c-vertical-center ui-justify-between ui-mb-4">
        <div class="c-col md:c-col-8">
          <span class="c-text-subtitle c-text-gray-300">
            Adiciona ou remove usuários nesse grupo.
          </span>
        </div>
      </div>

      <!-- Barra de pesquisa -->
      <div class="c-row ui-mb-4">
        <div class="c-col md:c-col-9">
          <c-search-bar
            v-model="term"
            size="lg"
            placeholder="Busque por usuário"
            data-cy="search-user"
          />
        </div>

        <div class="c-col md:c-col-3">
          <c-dropdown
            max-height
            label="Filtrar ativos/inativos"
            :options="filterActiveOptions"
            :value="filterActiveSelectedOption"
            data-cy="filterUserDropdown"
            @change="handleFilterActive"
          />
        </div>
      </div>

      <!-- Tabela -->
      <c-table
        :columns="columns"
        :data="users"
        :items-per-page="itemsPerPage"
        :pagination="pagination"
        @change-order-by="changeOrderBy"
        @change-pagination-per-page="changePaginationPerPage"
        @change-pagination-current-page="changePaginationCurrentPage"
      >
        <template v-slot:column-email="{ item: user }">
          <!-- Adiciona um soft wrap no email, para ele quebrar após o @ -->
          {{ user.email.split('@')[0] }}@<WBR />{{ user.email.split('@')[1] }}
        </template>

        <template v-slot:column-actions="{ item: user }">
          <c-toggle
            :value="hasGroup(user)"
            class="ui-mr-2"
            :data-cy="`toggleUser-${ user.email }`"
            @input="status => switchGroup(user.id, status)"
          />
        </template>
      </c-table>

      <!-- Botão Voltar -->
      <div class="c-row c-horizontal-end ui-mt-6">
        <div class="c-col-1/4">
          <c-button
            outline
            full
            data-cy="backButton"
            @click="$router.push({ name: 'groups' })"
          >
            Voltar
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { CButton, CSearchBar, CToggle, CDropdown } from '@estrategiahq/coruja-web-ui';
import CTable from '../../components/c-table/CTable';

const itemsPerPage = [30, 50, 100];

const filterActiveOptions = [
  { label: 'Todos', value: 'ALL' },
  { label: 'Usuários do grupo', value: 'ACTIVE' },
];

export default {
  components: {
    CButton,
    CDropdown,
    CSearchBar,
    CTable,
    CToggle,
  },
  props: {
    loggedUser: { type: Object, required: true },
  },
  data () {
    return {
      filterActiveOptions: filterActiveOptions,
      filterActiveSelectedOption: filterActiveOptions[1],
      itemsPerPage,
      columns: [
        { attribute: 'full_name', label: 'Nome', isOrderable: true },
        { attribute: 'email', label: 'Email', isOrderable: true },
        { attribute: 'actions', label: 'Ativo', maxWidth: '70px', justifyContent: 'end' },
      ],
      users: [],
      selectedGroup: {},
      term: '',
      pagination: {
        orderBy: 'full_name',
        sort: 'asc',
        totalPages: 1,
        perPage: itemsPerPage[0],
        currentPage: 1,
      },
    };
  },
  async mounted () {
    const id = this.$route.params.id;
    this.selectedGroup = await this.$s.group.get(id);
    await this.search();
  },
  watch: {
    term: debounce(function () {
      this.pagination.currentPage = 1;
      this.search();
    }, 300),
  },
  methods: {
    hasGroup (user) {
      return !!user.groups?.find(g => g.id === this.selectedGroup.id);
    },
    async search () {
      const searchParams = {
        ...this.pagination,
        term: this.term,
      };
      switch (this.filterActiveSelectedOption.value) {
      case 'ACTIVE': searchParams.groups = [this.selectedGroup.id]; break;
      }
      const { users, totalPages } = await this.$s.user.search(searchParams);
      this.pagination.totalPages = totalPages;
      this.users = users;
    },
    changeOrderBy (newOrder) {
      this.pagination.currentPage = 1;
      this.pagination.orderBy = newOrder.attribute;
      this.pagination.sort = newOrder.method.toLowerCase();
      this.search();
    },
    changePaginationPerPage (newPerPage) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = newPerPage;
      this.search();
    },
    changePaginationCurrentPage (newPage) {
      this.pagination.currentPage = newPage;
      this.search();
    },
    async switchGroup (userId, shouldAdd) {
      if (this.loggedUser.id === userId) {
        this.$toast.show({
          type: 'error',
          text: 'Você não pode alterar seus próprios grupos. Por favor, peça para outro usuário.',
          timeout: 0,
        });
        return;
      }

      const groupId = this.selectedGroup.id;
      const selectedUser = this.users.find(u => u.id === userId);
      if (shouldAdd) {
        await this.$s.group.addUserToGroup(userId, groupId);
        selectedUser.groups.push(this.selectedGroup);
      } else {
        await this.$s.group.removeUserFromGroup(userId, groupId);
        selectedUser.groups = selectedUser.groups.filter(g => g.id !== groupId);
      }
    },
    handleFilterActive(selectedOption) {
      this.filterActiveSelectedOption = selectedOption;
      this.search();
    },
  },
};
</script>
